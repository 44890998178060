<template>
  <div>
    <!-- pic72A81C3391A4B0388F05C73CCE8B065D.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/mln-unknown.jpg">
    <p>
      Another new LEGO Universe character will be befriending fans before long on My LEGO Network!
    </p>
    <p>
      Even before this mysterious new networker’s page goes live, folks who’ve signed up for the LEGO Universe email newsletter will get to see the character and learn more about…her? him?
    </p>
    <p>
      Every edition of the free LEGO Universe email newsletter is filled with fun-packed exclusives! If you haven’t already, be sure to
      <b><router-link :to="{ name: 'newsletter' }">sign up</router-link> by this Sunday, February 8th</b>
      if you want to get the next newsletter!
    </p>
    <p>
      Be the first to know!
    </p>
  </div>
</template>
